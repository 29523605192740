import React, { useState } from 'react';
import axios from 'axios';

const ImageUpload = () => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setUploading(true);
      setError(null);

      // Post file to backend API
      const response = await axios.post('http://localhost:3001/uploadTest', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setImageUrl(response.data.url);
      setUploading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setError(error.message);
      setUploading(false);
    }
  };

  return (
    <div className="image-upload">
      <h1>Upload PDF to Firebase</h1>

      {/* File input */}
      <input type="file"  accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" onChange={handleFileChange} />

      {/* Upload button */}
      <button onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Uploading...' : 'Upload PDF'}
      </button>

      {/* Display image or error */}
      {imageUrl && (
        <div>
          <h2>Uploaded Image:</h2>
          <img src={imageUrl} alt="Uploaded" style={{ width: '300px', height: 'auto' }} />
        </div>
      )}

      {error && (
        <div style={{ color: 'red' }}>
          <p>Error: {error}</p>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
